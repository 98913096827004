import HashWorker from './hash.worker.js'
const worker = new HashWorker()

export const createChunks = (file, SIZE = 1 * 1024 * 1024) => {
  const chunkList = []
  let cur = 0
  while (cur < file.size) {
    chunkList.push({
      file: file.slice(cur, cur + SIZE)
    })
    cur += SIZE
  }
  return chunkList
}

export const calculateHash = (chunkList) => {
  return new Promise((resolve) => {
    worker.postMessage({ chunkList })
    worker.onmessage = e => {
      const { hash } = e.data
      if (hash) {
        resolve(hash)
      }
    }
  })
}

export const getExt = (filename) => {
  return filename.slice(filename.lastIndexOf('.'))
}


export const compressImageToBase64 = (file, quality, width = 450, height = 600) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();
    reader.onload = function (event) {
      img.src = event.target.result;

      img.onload = function () {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        const base64 = canvas.toDataURL(file.type, quality);
        resolve(base64);
      };
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}


export const video2Base64 = (raw) => {
  return loadVideo(raw).then(videoElem => {
    const canvasElem = document.createElement('canvas')
    const { width, height } = videoElem
    canvasElem.width = width
    canvasElem.height = height
    canvasElem.getContext('2d').drawImage(videoElem, 0, 0, width, height)
    const dataURL = canvasElem.toDataURL('image/png', 0.9)
    return Promise.resolve(dataURL)
  })
}

export const loadVideo = (video) => {
  return new Promise(function (resolve, reject) {
    const videoElem = document.createElement('video')
    const dataUrl = URL.createObjectURL(video)
    videoElem.width = 450
    videoElem.height = 600
    videoElem.currentTime = 3
    videoElem.onloadeddata = function () {
      resolve(videoElem)
    }
    videoElem.onerror = function () {
      reject('video background loading failure')
    }
    videoElem.setAttribute('preload', 'auto')
    videoElem.src = dataUrl
  })
}